import { AxiosInstance } from 'axios'
import { LoginForm } from '@/pages/auth/login/types'
import { IUser } from '@/models/IUser'
import { ISubscription } from '@/models/ISubscription'

export class AuthService {
  constructor(private instance: AxiosInstance) {}

  async loginUser(payload: LoginForm) {
    return this.instance
      .post<{ token: string; user: IUser }>('/users/sign-in', payload)
      .then((response) => response.data)
  }

  googleLogin(userId: string, email: string) {
    return this.instance
      .post<{
        token: string
        userExists?: boolean
      }>('/users/google/sign-in', { googleUserId: userId, email })
      .then((response) => response.data)
  }

  appleLogin(userId: string) {
    return this.instance
      .post<{
        token: string
        userExists?: boolean
      }>('/users/apple/sign-in', { appleUserId: userId })
      .then((response) => response.data)
  }

  async checkEmailExists(email: string) {
    return this.instance
      .get<{ emailExists: boolean }>(`/users/check/email/${email}`)
      .then((response) => response.data)
  }

  checkGoogleExists(googleId: string, email: string) {
    return this.instance
      .post<{ userExists: boolean }>(`/users/check/google`, {
        googleUserId: googleId,
        email,
      })
      .then((response) => response.data)
  }
  checkAppleExists(appleId: string, email: string) {
    return this.instance
      .post<{ userExists: boolean }>(`/users/check/apple`, {
        appleUserId: appleId,
        email,
      })
      .then((response) => response.data)
  }

  async sendOtp(email: string, password: string) {
    return this.instance
      .post<{ token: string }>(`/users/verification`, {
        email,
        password,
      })
      .then((response) => response.data)
  }

  async resendOtp(token: string) {
    return this.instance.post(
      '/users/resend-otp',
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
  }

  async verifyOtp(otp: string, token: string) {
    return this.instance
      .post<{ token: string }>(
        '/users/verify-otp',
        { otp },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((response) => response.data)
  }

  async checkUsername(username: string, email: string) {
    return this.instance
      .get<{
        emailExists: boolean
        usernameExists: boolean
      }>('/users/check/exists', { params: { username, email } })
      .then((response) => response.data)
  }

  async registerUser(
    params: {
      username: string
      firstName: string
      lastName?: string
      occupation?: string
      birthday?: string | null
      gender?: string
      goals: string[]
      interests: string[]
      affiliateCode?: string | string[]
    },
    token: string,
  ) {
    return this.instance
      .post<{
        newUser: IUser
        responseToken: string
      }>('/users/sign-up-by-otp', params, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data)
  }

  async registerUserV2(
    email: string,
    password: string,
    affiliateCode?: string | string[],
  ) {
    return this.instance
      .post<IUser>('/users/sign-up-v2', {
        email,
        password,
        goals: [],
        interests: [],
        affiliateCode,
      })
      .then((response) => response.data)
  }

  async registerUserByGoogle(params: {
    email: string
    googleUserId: string
    firstName?: string
    lastName?: string
    goals: string[]
    interests: string[]
    affiliateCode?: string | string[]
  }) {
    return this.instance
      .post<{
        newUser: IUser
        responseToken: string
      }>('/users/sign-up-by-google-v2', params)
      .then((response) => response.data)
  }

  async registerUserByApple(params: {
    email: string
    appleUserId: string
    goals: string[]
    interests: string[]
    affiliateCode?: string | string[]
  }) {
    return this.instance
      .post<{
        newUser: IUser
        responseToken: string
      }>('/users/sign-up-by-apple-v2', params)
      .then((response) => response.data)
  }

  async recoverPassword(email: string) {
    return this.instance
      .post('/users/recover-password', { email })
      .then((response) => response.data)
  }

  async recoverRequest(id: string) {
    return this.instance
      .get(`/users/recover-password/${id}`)
      .then((response) => response.data)
  }

  async recoverPasswordFinish(id: string, password: string) {
    return this.instance
      .post(`/users/recover-password/reset/finish`, {
        id,
        password,
      })
      .then((response) => response.data)
  }

  async uploadUserAvatarV2(params: any) {
    return this.instance
      .post<void>('/users/upload-avatar/v2', params)
      .then((response) => response.data)
  }

  async updateProfile(params: any) {
    return this.instance
      .patch<IUser>('/users', params)
      .then((response) => response.data)
  }

  async me() {
    return this.instance
      .get<IUser>('/users/me')
      .then((response) => response.data)
  }

  async checkSubscription() {
    return this.instance
      .get<ISubscription>('/users/subscription-check')
      .then((response) => response.data)
  }

  async deleteAccount() {
    return this.instance.post('/users/delete').then((response) => response.data)
  }

  verifyAffiliate = (code: string | string[]) =>
    this.instance
      .get<boolean>(`/affiliate/verify-affiliate/${code}`)
      .then((response) => response.data)

  resendConfirmation = (email: string) =>
    this.instance.post(`/users/resend-confirmation`, { email })

  verifyEmail = (id: string) =>
    this.instance
      .patch<{
        newUser: IUser
        responseToken: string
      }>(`/users/confirm-email/${id}`)
      .then((response) => response.data)
}
