import { createContext, useCallback, useContext, useEffect } from 'react'
import Pusher from 'pusher-js'
import useApi from '@/hooks/useApi'
import { useAuthContext } from '../Auth'
import { useMessagesContext } from '../Inbox'

interface PusherContextProps {}

export const PusherContext = createContext<PusherContextProps>({})

export const PusherProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuthContext()
  const { api } = useApi()
  const messagesContext = useMessagesContext()
  // const { chats, chatRequests, archivedChats } = useChatsContext()

  const onEvent = useCallback(
    (eventName: string, data: any) => {
      console.log('called', eventName, data)

      switch (eventName) {
        case 'new-message':
          messagesContext.onNewMessage(data)
          break
        case 'delete-message':
          messagesContext.onMessageDelete(data)
          break
        case 'update-message':
          messagesContext.onMessageUpdate(data)
          break
        // case 'chat-update':
        //   chats.onChatUpdate(parsed.id, parsed)
        //   messagesContext.onChatInfo(parsed.id, parsed)
        //   break
        // case 'chat-request-update':
        //   chatRequests.onChatUpdate(parsed.id, parsed)
        //   messagesContext.onChatInfo(parsed.id, parsed)
        //   break
        // case 'chat-new':
        //   chats.onNewChat(parsed)
        //   break
        // case 'chat-deleted':
        //   chats.removeChat(parsed.id)
        //   chatRequests.removeChat(parsed.id)
        //   archivedChats.removeChat(parsed.id)
        //   break
        // case 'chat-request-new':
        //   chatRequests.onNewChat(parsed)
        //   break
        // case 'chat-requests-update-list-item':
        //   chats.setRequests(parsed)
        //   break
        default:
          break
      }
    },
    [messagesContext],
  )

  useEffect(() => {
    if (!user?.id) return

    const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY || '', {
      cluster: 'eu',
      channelAuthorization: {
        endpoint: '/pusher/auth',
        transport: 'ajax',
        customHandler: async ({ socketId }, callback) => {
          const response = await api.utils.authorizePusher(socketId)
          callback(null, response)
        },
      },
    })
    const channelName = `private-user-${user.id}`

    const channel = pusher.subscribe(channelName)
    channel.bind_global(onEvent)
    channel.bind('pusher:subscription_succeeded', () =>
      console.log('onSubscriptionSucceeded', channelName),
    )
    channel.bind('pusher:subscription_error', () =>
      console.log('onSubscriptionError', channelName),
    )

    pusher.connect()

    return () => {
      pusher.unsubscribe(channelName)
      pusher.unbind_all()
      pusher.disconnect()
    }
  }, [user?.id])

  return <PusherContext.Provider value={{}}>{children}</PusherContext.Provider>
}

export const usePusherContext = () => useContext(PusherContext)
