import { AxiosInstance } from 'axios'
import {
  ExploreAllContinent,
  ExploreAllType,
  IExplore,
} from '@/models/IExplore'
import { IPagination } from '@/models/IPagination'
import { IRating } from '@/models/IRating'
import { IVisaSelectorPref } from '@/models/IVisaSelectorPref'
import { ICountry } from '@/models/ICountry'
import { IVisaWizard } from '@/models/IVisaWIzard'
import { IAdvisor } from '@/models/IAdvisor'
import { IUserVisaWizard } from '@/models/IUserVisaWizard'

export type PossibleRating = 5 | 4 | 3 | 2 | 1

export interface IExploreReport {
  count: number
  average: number
  5: PossibleRating
  4: PossibleRating
  3: PossibleRating
  2: PossibleRating
  1: PossibleRating
}

export interface IExploreFilters {
  text?: string
  type?: ExploreAllType
  countryId?: string
  searchTypeCity?: boolean
  searchTypeCountry?: boolean
  continent?: ExploreAllContinent
  livingCostMin?: number
  livingCostMax?: number
  visaDigitalNomad?: boolean
  visaTourist?: boolean
  rating5?: boolean
  rating4?: boolean
  rating3?: boolean
  rating2?: boolean
  rating1?: boolean
  withReviews?: boolean
  withRemoters?: boolean
  withMatch?: boolean
  withWizards?: boolean
}

export interface IExploreRatingCreate {
  rating: number
  review?: string
  exploreId: string
}

export class ExploreService {
  constructor(private instance: AxiosInstance) {}

  visaCountriesList = (params: { text?: string; offset?: number }) => {
    return this.instance
      .get<IPagination & { countries: ICountry[] }>('/vha/countries', {
        params,
      })
      .then((response) => response.data)
  }

  popularVisas = () => {
    return this.instance
      .get<IPagination & { countries: ICountry[] }>('/vha/countries', {
        params: { limit: 3, sortBy: 'weight' },
      })
      .then((response) => response.data)
  }

  findCountries = (payload: IVisaSelectorPref) => {
    return this.instance
      .post<{ countries: IExplore[]; total: number; preferred?: IExplore }>(
        '/vha/find-countries',
        payload,
      )
      .then((response) => response.data)
  }

  findVisas = (countryId: string) => {
    return this.instance
      .get<{
        visas: IVisaWizard[]
        advisorProfile?: IAdvisor
        userWizard?: IUserVisaWizard
      }>(`/vha/find-visas/${countryId}`)
      .then((response) => response.data)
  }

  visaHelp = (payload: IVisaSelectorPref) => {
    return this.instance
      .post<{ visas: IExplore[]; total: number; preferred?: IExplore }>(
        '/vha/visa-help',
        payload,
      )
      .then((response) => response.data)
  }

  visaByCountryId = (id: string) =>
    this.instance
      .get<{ visa: IVisaWizard | undefined }>(`/vha/visas/country/${id}`)
      .then((response) => response.data)

  visaPrefs = () => {
    return this.instance
      .get<{ prefs: IVisaSelectorPref }>('/vha/prefs')
      .then((response) => response.data)
  }

  getExploreAllContent = (
    params: { limit?: number; token?: string } & IExploreFilters,
  ) =>
    this.instance
      .get<{ pagination: IPagination; items: IExplore[] }>('/explore-all', {
        params: { ...params, photoLimit: 1 },
      })
      .then((response) => response.data)

  getExploreById = (id: string) =>
    this.instance
      .get<IExplore>(`/explore-all/${id}`)
      .then((response) => response.data)

  getExploreByCountryId = (id: string) =>
    this.instance
      .get<IExplore>(`/explore-all/country/${id}`)
      .then((response) => response.data)

  getExploreReport = (exploreId: string) => {
    return this.instance
      .get<IExploreReport>(`/explore-rating/report/${exploreId}`)
      .then((response) => response.data)
  }

  private _rateExplore = (body: IExploreRatingCreate) => {
    return this.instance
      .post('/explore-rating', body)
      .then((response) => response.data)
  }
  public get rateExplore() {
    return this._rateExplore
  }
  public set rateExplore(value) {
    this._rateExplore = value
  }

  reviews = (params: {
    limit?: number
    exploreId?: string
    token?: string
  }) => {
    return this.instance
      .get<{ items: IRating[]; pagination: IPagination }>('/explore-rating', {
        params,
      })
      .then((response) => response.data)
  }
}
