import { IUser } from '@/models/IUser'
import events from './events.json'

type Primitive = string | number | boolean | null

const isDevelopment =
  process.env.NODE_ENV === 'development' || !process.env.NEXT_PUBLIC_SEGMENT_KEY

class Tracking {
  trackIdentity = (user: IUser) => {
    if (typeof window === 'undefined') return
    const payload = {
      name: `${user.firstName} ${user.lastName ?? ''}`,
      username: user.username,
      email: user.email,
      avatar: user.avatar,
    }
    if (isDevelopment) {
      return console.log('🎯 Tracking Identify ', payload)
    }
    return window.analytics.identify(user.id, { ...payload, platform: 'WA' })
  }

  trackEvent = (
    key: keyof typeof events,
    payload?: Record<string, Primitive | Primitive[]>,
  ) => {
    if (typeof window === 'undefined') return
    if (isDevelopment) {
      return console.log('🎯 Tracking event', key, payload)
    }
    return window.analytics.track(key, { ...payload, platform: 'WA' })
  }
}

export const tracker = new Tracking()
