import { AxiosInstance } from 'axios'
import {
  CreditCard,
  FormValues,
  SecondaryCoverage,
} from '@/contexts/Insurance/types'
import { IInsurance } from '@/models/IInsurance'
import { Applicants } from '@/components/insurance/types'

interface SpainInsuranceRequest {
  firstName: string
  lastName: string
  email: string
  dob: string
  spouse: number
  children: number
  pets: number
  country: string
  city: string
  arrivalDate: string
  kind: string[]
}

interface PremiumRequest {
  maxLimit: number
  deductible: number
  ages: number[]
  days: number
  isUS?: string
}

interface WorldTripsRequest extends FormValues {
  originalPremium: number
  transactionId: string
  DestinationList: string[]
}

interface WorldTripsResponse {
  insurance: IInsurance
  approvalUrl: string
}

interface AtlasTravelsRequest {
  USDest?: string
  ShowAuthCodes: string
  CoverageBeginDt: string
  CoverageEndDt: string
  PrimaryHomeCountry: string
  DestinationList: string[]
  Beneficiary?: string
  PhysicallyLocatedState?: string
  PhysicallyLocatedCountry?: string
  PolicyMax: string
  Deductible: string
  PrimaryFlWork?: string
  MailOpt?: string
  OnlineFulFill?: string
  ShippingCost?: string
  SendToName: string
  MailAddress1: string
  MailAddress2?: string
  MailCity: string
  MailCountry: string
  MailState?: string
  MailZip: string
  Phone: string
  EmailAddress: string
  ApplicantList: Applicants[]
  SecondaryCoverageList?: SecondaryCoverage[]
  CreditCard: CreditCard
}

export class InsuranceService {
  constructor(private instance: AxiosInstance) {}

  requestSpainInsurance = (body: SpainInsuranceRequest) => {
    return this.instance
      .post('/insurance/spain-quote', body)
      .then((response) => response.data)
  }

  calculatePremium = async (params: PremiumRequest) => {
    return this.instance
      .get<number>('/insurance/calculate-premium', { params })
      .then((response) => response.data)
  }

  saveWorldTrips = async (body: WorldTripsRequest) => {
    return this.instance
      .post<WorldTripsResponse>('/insurance/world-trips', body)
      .then((response) => response.data)
  }

  getWorldTripsByTransactionId = async (transactionId: string) => {
    return this.instance
      .get<IInsurance>(`/insurance/world-trips/${transactionId}`)
      .then((response) => response.data)
  }

  getWorldTripsByUserId = async () => {
    return this.instance
      .get<IInsurance>(`/insurance/world-trips`)
      .then((response) => response.data)
  }

  postAtlasTravels = async (body: AtlasTravelsRequest, id: string) => {
    return this.instance
      .post<IInsurance>(`/insurance/setup-insurance/${id}`, body)
      .then((response) => response.data)
  }
}
