import { AxiosInstance } from 'axios'

export interface UploadedFile {
  uploadFileUrl: string
  id: string
  mime: string
}

export class UtilsService {
  constructor(private instance: AxiosInstance) {}

  async uploadFile(
    entity: string,
    file: File,
    abortController?: AbortController,
  ) {
    const formData = new FormData()
    formData.append('file', file)
    return this.instance
      .post<UploadedFile>(`/file/upload-web/${entity}`, formData, {
        signal: abortController?.signal,
      })
      .then((response) => response.data)
  }

  async uploadFilesBulk(
    entity: string,
    files: File[],
    abortController?: AbortController,
  ) {
    const formData = new FormData()

    if (files.length > 0) {
      files.forEach((file) => {
        formData.append('files[]', file)
      })
    }

    return this.instance
      .post<UploadedFile[]>(`/file/upload-web/bulk/${entity}`, formData, {
        signal: abortController?.signal,
      })
      .then((response) => response.data)
  }

  authorizePusher = (socketId: string) =>
    this.instance
      .post<{
        auth: string
        channel_data?: string
        shared_secret?: string
      }>('/pusher/auth', { socketId })
      .then((response) => response.data)
}
