import { AxiosInstance } from 'axios'
import { IChatMessage, IChatV2 } from '@/models/IChatV2'
import { IPagination } from '@/models/IPagination'

export class InboxService {
  constructor(private instance: AxiosInstance) {}

  sendMessage = (chatId: string, value: string, replyMessageId?: string) =>
    this.instance
      .post<IChatMessage>(`/chat-messages/${chatId}`, {
        value: value.trim(),
        replyMessageId,
      })
      .then((response) => response.data)

  updateMessage = (id: string, chatId: string, value: string) =>
    this.instance
      .patch<IChatMessage>(`/chat-messages/${chatId}/${id}`, {
        value: value.trim(),
      })
      .then((response) => response.data)

  deleteMessage = (id: string, chatId: string) =>
    this.instance.delete(`/chat-messages/${chatId}/${id}`)

  sendGiphy = (chatId: string, value: string) =>
    this.instance
      .post<IChatMessage>(`/chat-messages/${chatId}/giphy`, {
        value,
      })
      .then((response) => response.data)

  sendMedia = (
    chatId: string,
    fileId: string,
    type: 'image' | 'video' | 'audio' | 'file',
  ) =>
    this.instance
      .post<IChatMessage>(`/chat-messages/${chatId}/file`, {
        fileId,
        type,
      })
      .then((response) => response.data)

  getList = (
    chatId: string,
    params: {
      limit?: number
      token?: string
    },
  ) =>
    this.instance
      .get<{
        chatInfo: IChatV2
        items: IChatMessage[]
        pagination: IPagination
      }>(`/chat-messages/${chatId}`, { params })
      .then((response) => response.data)

  createLastRead = (id: string) =>
    this.instance.get(`/chat-last-read/${id}`).then((response) => response.data)

  markAsUnread = (id: string) =>
    this.instance
      .get(`/chat-last-read/${id}/mark-as-unread`)
      .then((response) => response.data)

  acceptRequest = (chatId: string) =>
    this.instance
      .post(`/participant-status/${chatId}/accept`, {})
      .then((response) => response.data)

  blockRequest = (chatId: string) =>
    this.instance
      .post(`/participant-status/${chatId}/block`, {})
      .then((response) => response.data)

  declineRequest = (chatId: string) =>
    this.instance
      .post(`/participant-status/${chatId}/decline`, {})
      .then((response) => response.data)
}
