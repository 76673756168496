import { Slide, toast } from 'react-toastify'

interface Props {
  type?: 'success' | 'error'
  message: string
}

export const showToast = ({ type = 'success', message }: Props) => {
  toast[type](message, {
    toastId: type,
    position: toast.POSITION.BOTTOM_CENTER,
    icon: false,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    transition: Slide,
    className: 'toast',
  })
}
