import { AxiosInstance } from 'axios'
import { PaymentIntent } from '@stripe/stripe-js'
import { PaymentReason } from '@/constants/payments'
import { IAdvisor } from '@/models/IAdvisor'

export interface IntentBase {
  type: PaymentReason
}

export interface CreateAdvisorIntent extends IntentBase {
  wizardId: string
}

export interface BookConsultationIntent extends IntentBase {
  advisorId: string
}

export interface WizardPaymentIntent extends IntentBase {
  wizardPaymentId: string
}

export interface CreateDocCertificationIntent extends IntentBase {
  wizardId: string
  certificationId: string
}

export interface IPaymentIntent extends PaymentIntent {
  metadata: {
    discount?: string
    paymentId?: string
    discountReason?: string
  }
}

export class PaymentsService {
  constructor(private instance: AxiosInstance) {}

  createPaymentIntent = (type: PaymentReason, body: unknown) => {
    return this.instance
      .post<IPaymentIntent>(`/stripe/payment-intent/${type}`, body)
      .then((response) => response.data)
  }

  cancelSubscription = () => {
    return this.instance
      .post(`/stripe/cancel-pro`)
      .then((response) => response.data)
  }

  connect = () =>
    this.instance
      .get<string>('/stripe/connect')
      .then((response) => response.data)

  connectStatus = () =>
    this.instance
      .get<{ enabled: boolean }>('/stripe/connect-status')
      .then((response) => response.data)

  retrieveIntent = (id: string) =>
    this.instance
      .get<IPaymentIntent>(`/stripe/retrieve-intent/${id}`)
      .then((response) => response.data)
}
