import { AxiosInstance } from 'axios'
import { IPagination } from '@/models/IPagination'
import { ICountry } from '@/models/ICountry'
import { IState } from '@/models/IState'

export class LocationService {
  constructor(private instance: AxiosInstance) {}

  getCountries = (params: { name?: string; token?: string }) => {
    return this.instance
      .get<IPagination & { countries: ICountry[] }>('/countries', { params })
      .then((response) => response.data)
  }

  getCountryById = (id: string) =>
    this.instance
      .get<ICountry>(`/countries/${id}`)
      .then((response) => response.data)

  getStates = (countryId: string, params: { name?: string }) =>
    this.instance
      .get<IState[]>(`/countries/${countryId}/states`, { params })
      .then((response) => response.data)
}
