import { useCallback, useEffect, useMemo, useState } from 'react'
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { useRouter } from 'next/router'
import Api from '@/utils/Api'
import { showToast } from '@/utils/toast'
import { useAuthContext } from '@/contexts/Auth'
import { checkIsGatedRoute, logout } from '@/utils/auth'

export type API_STATUS =
  | 'default'
  | 'loading'
  | 'loaded'
  | 'posting'
  | 'posted'
  | 'deleting'
  | 'deleted'

interface ApiError {
  statusCode: number
  message: string | string[]
  error: string
}

export default function useApi() {
  const router = useRouter()
  const { token } = useAuthContext()
  const [status, setStatus] = useState<API_STATUS>('default')
  const [error, setError] = useState<string | null>(null)
  const api = useMemo(() => new Api(), [])

  const requestInterceptor = useCallback(
    (config: InternalAxiosRequestConfig) => {
      if (config.method === 'get') setStatus('loading')
      if (
        config.method === 'post' ||
        config.method === 'put' ||
        config.method === 'patch'
      )
        setStatus('posting')
      if (config.method === 'delete') setStatus('deleting')

      const newConfig = { ...config }
      newConfig.headers &&
        token &&
        (newConfig.headers.Authorization = `Bearer ${token}`)

      return { ...newConfig }
    },
    [token],
  )

  const responseInterceptor = useCallback((response: AxiosResponse) => {
    const { config } = response
    if (config.method === 'get') setStatus('loaded')
    if (
      config.method === 'post' ||
      config.method === 'put' ||
      config.method === 'patch'
    )
      setStatus('posted')
    if (config.method === 'delete') setStatus('deleted')
    setError(null)
    return response
  }, [])

  const errorInterceptor = useCallback(
    (error: AxiosError<ApiError>) => {
      const isGatedRoute = checkIsGatedRoute(router.pathname)
      const statusCode = error.response?.status

      if (statusCode === 401 && isGatedRoute) {
        return logout()
      }

      const errorMessages = error.response?.data?.message
      if (errorMessages) {
        // API error
        const message = Array.isArray(errorMessages)
          ? errorMessages[0]
          : errorMessages

        setStatus('default')
        setError(message)

        // suppress unauthorized on public facing pages
        statusCode !== 401 && showToast({ type: 'error', message })
      } else {
        // Axios/Network errors
        const message = error.message

        setStatus('default')
        setError(message)

        // suppress unauthorized on public facing pages
        statusCode !== 401 && showToast({ type: 'error', message })
      }
      return Promise.reject(error)
    },
    [router.pathname],
  )

  useEffect(() => {
    const requestRef = api.client.interceptors.request.use(requestInterceptor)

    const responseRef = api.client.interceptors.response.use(
      responseInterceptor,
      errorInterceptor,
    )

    return () => {
      api.client.interceptors.request.eject(requestRef)
      api.client.interceptors.response.eject(responseRef)
    }
  }, [api, requestInterceptor, responseInterceptor, errorInterceptor])

  return { api, status, error }
}
