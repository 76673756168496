import { AxiosInstance } from 'axios'

export class VisaService {
  constructor(private instance: AxiosInstance) {}

  storePref = (data: object) =>
    this.instance
      .post<{ [key: string]: string }>('/vha/visa-quiz-complete', data)
      .then((response) => response.data)
}
