import React, { createContext, useContext, useState } from 'react'
import { IChatMessage, IChatV2 } from '@/models/IChatV2'
import { useAuthContext } from '../Auth'

interface MessagesContextInterface {
  itemsByChat: Record<string, IChatMessage[]>
  chatInfoById: Record<string, IChatV2>
  onChatInfo: (chatId: string, v: IChatV2) => void
  onMessages: (
    chatId: string,
    items: (IChatMessage & { showSkeleton?: boolean })[],
  ) => void
  onNextMessages: (
    chatId: string,
    items: (IChatMessage & { showSkeleton?: boolean })[],
  ) => void
  onNewMessage: (m: IChatMessage) => void
  onMessageDelete: (m: IChatMessage) => void
  onMessageUpdate: (m: IChatMessage) => void
  onArchivedChatRestore: (id: string) => void
}
export const MessagesContext = createContext<MessagesContextInterface>(
  {} as MessagesContextInterface,
)

export const MessagesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const authContext = useAuthContext()

  // eslint-disable-next-line no-spaced-func
  const [itemsByChat, setItemsByChat] = useState<
    Record<string, (IChatMessage & { showSkeleton?: boolean })[]>
  >({})
  const [chatInfoById, setChatInfoById] = useState<Record<string, IChatV2>>({})

  const mapMessages = (messages: IChatMessage[] = []) =>
    messages.map((message) => {
      return {
        ...message,
        arrived: message.arrived ?? true,
      }
    })

  const onChatInfo = (chatId: string, chatInfo: IChatV2) => {
    setChatInfoById((prev) => ({
      ...prev,
      [chatId]: {
        ...(prev[chatId] ?? {}),
        ...chatInfo,
        participants:
          chatInfo.type === 'private'
            ? chatInfo.participants.filter(
                ({ userId }) => userId !== authContext.user?.id,
              )
            : chatInfo.participants,
        otherParticipant:
          chatInfo.type === 'private'
            ? chatInfo.participants.find(
                (participant) => participant.userId !== authContext.user?.id,
              )
            : undefined,
      },
    }))
  }

  const onMessages = (chatId: string, messages: IChatMessage[]) => {
    setItemsByChat((prev) => ({
      ...prev,
      [chatId]: mapMessages(messages),
    }))
  }

  const onNextMessages = (chatId: string, messages: IChatMessage[]) => {
    setItemsByChat((prev) => ({
      ...prev,
      [chatId]: mapMessages(prev[chatId].concat(messages)),
    }))
  }

  const onNewMessage = (message: IChatMessage) => {
    setItemsByChat((prev) => ({
      ...prev,
      [message.chatId]: prev[message.chatId]
        ? mapMessages([
            message,
            ...prev[message.chatId].filter(({ id }) => id !== message.id),
          ])
        : [message],
    }))
  }

  const onMessageUpdate = (parsed: IChatMessage) => {
    setItemsByChat((prev) => ({
      ...prev,
      ...(prev[parsed.chatId]
        ? {
            [parsed.chatId]: prev[parsed.chatId].map((item) =>
              item.id === parsed.id ? parsed : item,
            ),
          }
        : {}),
    }))
  }

  const onMessageDelete = (parsed: IChatMessage) => {
    setItemsByChat((prev) => ({
      ...prev,
      [parsed.chatId]: prev[parsed.chatId]?.length
        ? mapMessages([
            ...prev[parsed.chatId].filter(({ id }) => id !== parsed.id),
          ])
        : [],
    }))
  }

  const onArchivedChatRestore = (id: string) => {
    setChatInfoById((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        archived: false,
      },
    }))
  }

  return (
    <MessagesContext.Provider
      value={{
        itemsByChat,
        chatInfoById,
        onChatInfo,
        onMessages,
        onNewMessage,
        onNextMessages,
        onMessageDelete,
        onMessageUpdate,
        onArchivedChatRestore,
      }}
    >
      {children}
    </MessagesContext.Provider>
  )
}

export const useMessagesContext = (): MessagesContextInterface =>
  useContext(MessagesContext)
